import { Challenge } from '@shared/types/challenge';
import { Status } from '@shared/types/solution';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { moduleService, subjectService } from 'services';
import ChallengeCard from 'views/home/challengeCard';
import Menubar from 'components/menubar';
import Footer from 'components/footer/Footer';
const ModuleOverview: React.FC = () => {
  const { moduleId } = useParams<{ moduleId: string }>();
  const [module, setModule] = useState<any>(null);
  const [challenges, setChallenges] = useState<any>([]);
  const [solutions, setSolutions] = useState([]);   // Stores the list of solutions
  const [currentIx, setCurrentIx] = useState<number | null>(null); // Index of the current challenge
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchModule = async () => {
      try {
        const response = await moduleService.get(moduleId);
        setModule(response.data);
      } catch (error) {
        console.error("Error fetching module:", error);
      }
    };

    const fetchModuleChallenges = async () => {
      try {
        const challengesResponse = await moduleService.getChallenges(moduleId!);
        const solutionsResponse = await moduleService.getSolutions(moduleId!);

        const challenges = challengesResponse.data;
        const solutions = solutionsResponse.data;

        // Determine the index of the current challenge
        const currentIx = challenges.findIndex((challenge, ix) => {
          const solution = solutions.find(s => s.challengeId === challenge._id);
          return solution?.status !== Status.Completed;
        });

        setChallenges(challenges);
        setSolutions(solutions);
        setCurrentIx(currentIx);
      } catch (error) {
        console.error("Error fetching module challenges:", error);
      }
    };

    if (moduleId) {
      fetchModule();
      fetchModuleChallenges();
    }
  }, [moduleId]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await subjectService.find();
        setSubjects(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };

    fetchSubjects();
  }, []);


  const subjectMap = subjects.reduce((acc: { [key: string]: any }, subject) => {
    acc[subject._id] = subject;
    return acc;
  }, {});

  console.log("thisischallenges", challenges)

  return (
    <>
    <div className="flex bg-[#fefdfc] pb-6">
      <Menubar activePage="modules" />

      <div className="pt-4 flex w-full flex-col ">
        <div className='relative pl-10 pr-6'>
          <div className=" p-4 w-full top-0  bg-[#DCFCE7] rounded-2xl overflow-hidden border border-solid border-[#DCFCE7] ">
            <img
              className="relative w-20 h-20  object-cover"
              alt="Element graph monitor"
              src="https://c.animaapp.com/ja9imYGr/img/809503-graph-monitor-multimedia-stats-technology-icon-1@2x.png"
            />
            <img
              className="relative ml-6 w-20 h-20 object-cover"
              alt="Element"
              src="https://c.animaapp.com/ja9imYGr/img/7440395-1@2x.png"
            />
          </div>

          <div className="mb-2 flex items-center justify-between rounded-xl border border-colors-border-neutral bg-colors-background-elevated pt-4">
            <h2 className="whitespace-nowrap font-title-large text-[length:var(--title-large-font-size)] font-[number:var(--title-large-font-weight)] leading-[var(--title-large-line-height)] tracking-[var(--title-large-letter-spacing)] text-[#111111] [font-style:var(--title-large-font-style)]">
              {module?.name || "Loading..."}
            </h2>
          </div>

          <p className="relative h-5  font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-[#111111] text-[length:var(--body-small-strong-font-size)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] whitespace-nowrap [font-style:var(--body-small-strong-font-style)]">
            <span className="underline font-body-small-strong [font-style:var(--body-small-strong-font-style)] font-[number:var(--body-small-strong-font-weight)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] text-[length:var(--body-small-strong-font-size)]">
              Subjects
            </span>

            <span className="font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-[#111111] text-[length:var(--body-small-strong-font-size)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] [font-style:var(--body-small-strong-font-style)]">
              {" "}
              &gt;{" "}
            </span>

            <span className="underline font-body-small-strong [font-style:var(--body-small-strong-font-style)] font-[number:var(--body-small-strong-font-weight)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] text-[length:var(--body-small-strong-font-size)]">
              Artificial Intelligence
            </span>

            <span className="font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-[#111111] text-[length:var(--body-small-strong-font-size)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] [font-style:var(--body-small-strong-font-style)]">
              {" "}
              &gt; Module Linear Regression{" "}
            </span>
          </p>

        </div>
        <div className="flex flex-col gap-6 px-10 pt-4">
          <div className="flex flex-row gap-6">
            <div className="relative w-6/12">
              {/* <video className="w-full h-[227px] object-cover" controls>
                <source src="https://res.cloudinary.com/djmoafb0i/video/upload/v1732130753/fnahq8cg4jr6uvotod4w.mp4" type="video/mp4" />
              </video> */}
              <img className='w-full h-[227px] object-cover' src='/assets/icons/modules/linear_regression.jpg' />
            </div>

            <div className="flex flex-col w-full">
              <p className="relative  self-stretch mt-[-1.00px] font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-colors-content-primary text-[length:var(--body-small-strong-font-size)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] [font-style:var(--body-small-strong-font-style)]">
                An Introduction to Linear Regression Analysis
              </p>
              <p className=" pt-2 relative font-body-small font-[number:var(--body-small-font-weight)] text-colors-content-secondary text-[length:var(--body-small-font-size)] text-justify tracking-[var(--body-small-letter-spacing)] leading-[var(--body-small-line-height)] [font-style:var(--body-small-font-style)]">
                {module?.description || "Description not available."}
              </p>
            </div>
          </div>

          <div className="mb-2 flex items-center justify-between rounded-xl border border-colors-border-neutral bg-colors-background-elevated pt-4">
            <h2 className="whitespace-nowrap font-title-large text-[length:24px] font-bold leading-[var(--title-large-line-height)] tracking-[var(--title-large-letter-spacing)] text-[#111111] [font-style:var(--title-large-font-style)]">
              Challenges
            </h2>
          </div>

          <div className="flex flex-wrap gap-3 mb-10">
            {challenges.map((challenge, ix) => {
              const solution = solutions.find(s => s.challengeId === challenge._id);
              const unlocked = currentIx === ix; // Current challenge is unlocked
              const enableRetake =
                currentIx < 0 || // All challenges completed
                (ix < currentIx && solution?.status === Status.Completed); // Retake completed challenges before the current one

              return (
                <div
                  key={challenge._id}
                  className={`bg-colors-background-elevated w-full rounded-2xl overflow-hidden border border-solid border-colors-border-neutral shadow-boxy-shadow ${
                    /* Locking mechanism for challenges based on 'unlocked' and 'enableRetake' states
                    Commented out for the development phase
                    !unlocked && !enableRetake ? "opacity-50 cursor-not-allowed pointer-events-none" : "" */
                    ""
                  }`}
                >
                  <ChallengeCard
                    challenge={challenge}
                    subject={subjectMap[challenge.subjectId]}
                    bgClass="bg-colors-background-elevated"
                    textColor="text-[#111111]"
                    disable = {!unlocked && !enableRetake}
                  />
                </div>

              );
            })}
          </div>
        </div>
      </div>
    </div>
    <div className="border-t-5 border-solid border-black">
      <Footer />
    </div>
    </>
  );

};

export default ModuleOverview;