import React, { useState, useEffect, useRef, useContext } from "react";
import { MasterStages } from "utils/masterStages";
import { useDrag, useDragLayer } from 'react-dnd';
import { Context } from "../context";
import { codeblockService } from "services";
import { IoClose } from "react-icons/io5";
import Documentation from "components/utils/documentation";
import QuizDisplay from "./quiz";
import Icon from "components/utils/icon";
import ParamModal from "./paramModal";
import { Codeblock } from "@shared/types/codeblock";
import { Challenge } from "@shared/types/challenge";
import { Solution, Status } from "@shared/types/solution";
import Skeleton from "react-loading-skeleton";
import Joyride from "react-joyride";
import stepsData from '../joyridedata.json';
interface ChallengeStagesProps {
  challenge: Challenge;
  _solved: boolean;
  solution?: Solution;
}

const CustomDragLayer = () => {
  const { isDragging, currentOffset, item } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
    item: monitor.getItem(),
  }));

  if (!isDragging || !currentOffset) {
    return null;
  }
  const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;

  console.log("`test draggable item`", item);
  return (
    <div className="pointer-events-none fixed w-[40vw] h-full top-0 left-0 z-[9999999]">
      <div
        style={{ transform, cursor: 'grabbing' }} // Ensure 'grabbing' cursor is applied here
        className="h-12 rounded-xl bg-colors-interactive-secondary border-2 border-colors-interactive-primary shadow-shadow-active"
      >
        <div className="inline-flex items-center gap-4 px-4 py-3">
          <Icon name='dragIndicator.svg' category='codeblocks' alt="Drag indicator" />
          <div className="relative  mt-[-1.00px] font-body-strong text-colors-content-primary">
            {item.label}
          </div>
        </div>
      </div>
    </div>
  );
};

const DraggableCodeBlock: React.FC<{ block: any; handleOpenDocumentModel: any; }> = ({ block, handleOpenDocumentModel }) => {
  const [showParamModal, setShowParamModal] = useState(false);
  const [parameters, setParameters] = useState(block.parameters || []);
  const context = useContext(Context);

  if (!context) {
    throw new Error('ChildComponent must be used within a provider');
  }


  const { droppedCodeBlocks: droppedItems, setDroppedCodeBlocks } = context;
  console.log("test block", block, droppedItems);

  const isDragged = droppedItems.some((item: any) => item._id === block._id);

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: 'CODEBLOCK',
    item: { _id: block._id, label: block._codeblock.label },
    canDrag: !isDragged,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (monitor.didDrop() && block.parameters.length > 0) {
        setShowParamModal(true);
      }
    }
  }));

  useEffect(() => {
    dragPreview(new Image()); // Disable default preview
  }, [dragPreview]);

  // Handle submit to update droppedItems with new parameters
  const handleSubmit = (updatedParams: any[]) => {
    console.log('Submitted Parameters:', updatedParams);

    // Find the index of the item to update
    const itemIndex = droppedItems.findIndex((item: any) => item._id === block._id);

    if (itemIndex === -1) return; // If the item is not found, exit

    // Update the parameters with defaults if no value is provided
    const updatedParameters = updatedParams.map(param => ({
      ...param,
      value: param.value || param.default
    }));

    // Create a new item with updated parameters
    const newItem = {
      ...droppedItems[itemIndex],
      parameters: updatedParameters
    };

    // Clone the array and replace the old item with the updated one
    const updatedDroppedItems = Array.from(droppedItems);
    updatedDroppedItems[itemIndex] = newItem;

    // Update the state
    setDroppedCodeBlocks(updatedDroppedItems);
    setShowParamModal(false);
  };

  return (
    <>
      {showParamModal && (
        <ParamModal
          heading="Supply Parameters"
          parameters={parameters}
          onClose={() => setShowParamModal(false)}
          onSubmit={handleSubmit}
        />
      )}

      <div
        ref={drag}
        className={` text-sm mt-2 text-gray-900 mx-6 h-12  rounded-xl overflow-hidden border border-solid 
        ${isDragging
            ? 'bg-colors-content-interactive-secondary border-colors-interactive-primary cursor-not-allowed shadow-shadow-active'
            : 'bg-colors-content-primary-background border-colors-border-neutral hover:bg-colors-interactive-secondary hover:cursor-grab'
          }
        ${isDragged ? 'opacity-50 cursor-not-allowed bg-colors-interactive-secondary text-colors-interactive-primary' : ''} `}>
        <div id={`codeblock-${block._id}`} className="inline-flex items-center gap-4 px-4 py-3">
          <div className="inline-flex items-center gap-3">
            <Icon name={`dragIndicator${isDragged ? "" : "Default"}.svg`} category='codeblocks' alt="Drag indicator" />
            <div className={`relative ${isDragging ? 'text-colors-interactive-primary' : 'text-colors-content-primary'} ${isDragged ? 'opacity-50 cursor-not-allowed text-colors-interactive-primary' : ''}  mt-[-1.00px] font-body-strong`}>
              {block._codeblock.label}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


const ChallengeStages: React.FC<ChallengeStagesProps> = ({
  challenge,
  _solved,
  solution
}) => {
  const challengeId = challenge._id;
  const [expandedStages, setExpandedStages] = useState<{
    [key: number]: boolean;
  }>({});
  const [codeBlocks, setCodeBlocks] = useState<{ [key: string]: Codeblock }>(
    {}
  );
  const [showDocumentModel, setShowDocumentModel] = useState(false);
  const [html, setHtml] = useState<string>('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [quizIds, setQuizIds] = useState<{ [key: number]: string | null }>({});
  const [loading, setLoading] = useState(true);
  const [run, setRun] = useState(false); // Tour start/stop state
  const [stepIndex, setStepIndex] = useState(0); // Step index to reset tour
  const [steps, setSteps] = useState(stepsData);
  const context = useContext(Context);

  if (!context) {
    throw new Error('ChildComponent must be used within a provider');
  }
  const { codeBlocksRef, currentStepIndex, setCurrentStepIndex, currentSolution, setTourStarted, tourStarted } = context;
  useEffect(() => {
    if (!tourStarted) {
      setStepIndex(0); // Reset to the first step
      setTimeout(() => {
        setStepIndex(0); // Reset to the first step
        setRun(true); // Restart the tour
      }, 100); // Small delay to allow Joyride to reinitialize
      setTourStarted(true); // Mark the tour as started
    }
  }, [tourStarted, setTourStarted]);
  const restartTour = () => {
    setRun(false); // Temporarily stop the tour
    setTimeout(() => {
      setStepIndex(0); // Reset to the first step
      setRun(true); // Restart the tour
    }, 100); // Small delay to allow Joyride to reinitialize
  };
  // Callback to handle tour events
  const handleJoyrideCallback = (data) => {
    const { action, index, type } = data;
  
    if (type === "step:after" && action === "next") {
      setStepIndex(index + 1); // Move to the next step
    }
  
    if (type === "step:after" && action === "prev") {
      setStepIndex(index - 1); // Move to the previous step
    }
  
    if (type === "tour:end") {
      setStepIndex(0); // Reset after tour ends
    }
  };
 
  const solutionSteps = challenge.solutionSteps ?? [];

  let calculatedStepIndex = (() => {
    if (!currentSolution) return 0;
    if (currentSolution.status === Status.Completed) return solutionSteps.length - 1;
    return Math.max(0, currentSolution.progressedStepCount - 1); // Ensures non-negative index
  })();

  // Update the state with the calculated index
  useEffect(() => {
    setCurrentStepIndex(calculatedStepIndex);
    console.log("Current Step Index:", calculatedStepIndex);
  }, [calculatedStepIndex, setCurrentStepIndex]);

  const handleNextModule = () => {
    // Move to the next stage or loop back to the first stage if it's the last one
    if (currentStepIndex < challenge.solutionSteps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      setCurrentStepIndex(0);
    }
  };

  const handlePreviousModule = () => {
    // Move to the previous stage
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  console.debug('current solution', currentSolution)
  const currentStep = challenge.solutionSteps[currentStepIndex];

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleToggleStage = (stageId: number) => {
    setExpandedStages((prev) => ({ ...prev, [stageId]: !prev[stageId] }));
  };

  useEffect(() => {
    const fetchCodeBlock = async (availableCodeblock: any) => {
      try {
        const codeblockId = availableCodeblock.codeblockId;
        const response = await codeblockService.get(codeblockId);
        const codeblock = response.data;

        // Merge parameters and parameterValues based on the `name` field
        const mergedParameters = codeblock.parameters.map((param: any) => {
          const correspondingParamValue = availableCodeblock.parameterValues.find(
            (paramValue: any) => paramValue.name === param.name
          );

          return {
            ...param,
            value: correspondingParamValue ? correspondingParamValue.value : param.default, // Use value if available, otherwise default
          };
        });

        codeblock.parameters = mergedParameters

        // Merge availableCodeblock and fetched 

        // const combinedCodeblock = { ...availableCodeblock, ...codeblock };

        const combinedCodeblock = { ...availableCodeblock, _codeblock: codeblock, parameters: mergedParameters };

        console.log("test combine code block", combinedCodeblock)
        // // Update codeBlocksRef with the combined object
        codeBlocksRef.current = { ...codeBlocksRef.current, [codeblockId]: combinedCodeblock };

        // Update the state with the combined object
        setCodeBlocks((prev) => ({ ...prev, [codeblockId]: combinedCodeblock }));
      } catch (error) {
        console.error("Error fetching code block:", error);
      }
    };

    console.log("Codeblocks outside", codeBlocks);

    const fetchAllCodeBlocks = async () => {
      for (const stage of challenge.solutionSteps) {
        for (const block of stage.availableCodeblocks) {
          // Fetch each block if it's not already in the ref
          if (!codeBlocksRef.current[block.codeblockId]) {
            await fetchCodeBlock(block);
          }
        }
      }
      setLoading(false);
    };

    fetchAllCodeBlocks();

    // Set quizId from solution stages
    const quizIds: { [key: number]: string | null } = {};
    challenge.solutionSteps.forEach((stage: any) => {
      quizIds[stage._id] = stage.quizId;
    });
    setQuizIds(quizIds);
  }, [challenge.solutionSteps]);

  function renderTextWithLineBreaks(text: string) {
    const htmlText = text.replace(/\\n/g, '<br>');
    return { __html: htmlText };
  }
  const handleOpenDocumentModel = (detailedDescription: any) => {
    setShowDocumentModel(true);
    setHtml(detailedDescription);
  }
  return (
    <>
      {showDocumentModel && (
        <div className="inset-0 z-50 mt-[58px] overflow-y-auto mb bg-gray-800 bg-opacity-60">
          <div className="flex items-center justify-center min-h-screen">
            <div className="bg-white rounded-lg shadow-lg  max-w-4xl mx-4">
              <div className="flex items-center justify-between p-4 border-b border-gray-400">
                <h2 className="text-lg font-semibold text-gray-900">Knowledge Center</h2>
                <button
                  className="text-gray-600"
                  onClick={() => setShowDocumentModel(false)}
                >
                  <IoClose />
                </button>
              </div>
              <div className="p-4">
                <div className="container mx-auto p-4">
                  <Documentation html={html} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Joyride
        steps={stepsData.filter((step) => {
          return document.querySelector(step.target);
        })}
        run={run}
        stepIndex={stepIndex}
        callback={handleJoyrideCallback} // Manage tour status
        continuous={true} // Automatically go to the next step
        scrollToFirstStep={true}
        showProgress={false}
        locale={{
          back: "Previous", // Label for the Back button
          close: "Finish",  // Label for the Close/Finish button
          last: "End Tour", // Label for the Last button
          next: "Next",  // Label for the Next button
        }}
        styles={{
          options: {
            arrowColor: "#fff", // Arrow color
            overlayColor: "rgba(0, 0, 0, 0.5)", // Overlay background color
            primaryColor: "#a21caf", // Main color for buttons
          },
          tooltip: {
            display: "flex", // Ensure the footer uses flexbox
            flexDirection: "column", // Stack content vertically
          },
          tooltipFooter: {
            display: "flex", // Enable flexbox for the footer
            justifyContent: "space-between", // Align buttons to opposite ends
            alignItems: "center", // Center buttons vertically
            margin: "0 auto",
            width: "100%", // Ensure the footer spans the full width of the tooltip
          },
          buttonNext: {
            backgroundColor: "#a21caf", // Primary color for the Next button
            color: "#fff", // Text color
          },
          buttonBack: {
            backgroundColor: "#f7e9f9", // Secondary color for the Previous button
            color: "#a21caf", // Text color
            marginLeft: 0,
            borderRadius: "4px", // Slightly round the button's border
            // border: "1px solid #a21caf", // Optional: Add a border for better visibility
          },
        }}
      />
      <div className="flex h-screen flex-col">
        {/* Sticky Header */}
        <div className="sticky top-0 z-10 border-b-[0.5px]  border-colors-border-neutral bg-colors-background-screen [border-bottom-style:solid]">
          <div className="flex h-[52px] items-center gap-2 px-4 py-0">
            <div className="flex items-center gap-2 flex-1">

              <Icon name="list_alt_check.svg" category="challenge" className="relative w-5 h-5"
                alt="List alt check" />

            <div className="relative mt-[-1.00px] font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-colors-content-secondary text-[length:var(--body-small-strong-font-size)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] whitespace-nowrap [font-style:var(--body-small-strong-font-style)]">
              {challenge?.name}
            </div>
          </div>
          <div className="flex justify-end ">
            <button className="inline-flex justify-center gap-2.5 px-2.5 py-1.5 flex-[0_0_auto] bg-colors-background-elevated rounded-lg border border-solid border-colors-border-neutral items-center relative" onClick={restartTour}>Start Tour</button>
          </div>
        </div>
        </div>
        {/* Scrollable Content */}
        <div className="hide-scrollbar flex-1 overflow-y-auto border-l-[0.5px] border-r-[0.5px] border-colors-border-neutral ">
          {loading ? (
            <>
              {/* Show Skeleton while loading */}
              <div style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '10px' }}>
                <Skeleton height={200} width={'100%'} borderRadius={10} />
              </div>
              <div style={{ marginBottom: '20px', marginLeft: '10px' }}>
                <Skeleton height={200} width={'100%'} borderRadius={10} />
              </div>
              <div style={{ marginBottom: '20px', marginLeft: '10px' }}>
                <Skeleton height={200} width={'100%'} borderRadius={10} />
              </div>
            </>
          ) : (
            currentStep && (
              <>
                {/* Module header */}
                <div id="challenge" className="relative left-0 mb-48">
                  <div className="relative h-7 top-4 left-6  text-[#111111] font-semibold text-xl  whitespace-nowraptop-0 font-body-small-strong text-colors-content-primary text-[length:var(--body-small-strong-font-size)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] [font-style:var(--body-small-strong-font-style)]">
                    {currentStep.briefDescription}
                  </div>
                  <div id="solveitAsst" className="overflow-y-auto h-screen pb-48 hide-scrollbar">
                    <Documentation html={currentStep.detailedDescription} />
                  </div>
                  {/* Code blocks section */}
                  {currentStep.availableCodeblocks.length > 0 && (
                    <div className="mx-6 mt-8">
                      <div className="flex  flex-col items-start relative bg-colors-background-screen rounded-[10px] overflow-hidden border-[0.5px] border-solid border-colors-border-neutral">
                        <div className="flex items-center justify-center gap-1.5 px-4 py-1.5 self-stretch  bg-colors-content-primary-background border-b-[0.5px] [border-bottom-style:solid] border-colors-border-neutral relative flex-[0_0_auto]">
                          <div className="relative flex-1 mt-[-0.50px] font-label-uppercase-strong font-[number:var(--label-uppercase-strong-font-weight)] text-colors-content-secondary text-[length:var(--label-uppercase-strong-font-size)] tracking-[var(--label-uppercase-strong-letter-spacing)] leading-[var(--label-uppercase-strong-line-height)] [font-style:var(--label-uppercase-strong-font-style)]">
                            STEPS
                          </div>
                        </div>

                        <div className="flex items-center justify-center gap-3 px-4 py-3.5 self-stretch  relative flex-[0_0_auto]">

                          <Icon name="swipe_right.png" category="challenge" className="relative flex-[0_0_auto]"
                            alt="swipe_right" />

                          <p className="relative flex-1 mt-[-1.00px] font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-colors-content-primary text-[length:var(--body-small-strong-font-size)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] [font-style:var(--body-small-strong-font-style)]">
                            Drag the code blocks below into the Canvas on the right to see the output or test it.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Render available codeblocks */}
                  <div className={`${currentStep.availableCodeblocks.length > 0 ? "mb-12" : ""}`}>
                    {currentStep.availableCodeblocks.map((block: any) => {
                      // const codeBlock = codeBlocks[block.codeblockId];
                      const codeblock = Object.values(codeBlocks).find(
                        (codeBlock: any) => codeBlock._id === block._id
                      );
                      return (
                        <div id="codeblock" key={block._id} className="relative top-4">
                          {codeblock && (
                            <>
                              <DraggableCodeBlock
                                block={codeblock}
                                handleOpenDocumentModel={handleOpenDocumentModel}
                              />
                              <CustomDragLayer />
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  {/* Quiz display */}
                  {currentStep.quizId && (
                    <QuizDisplay quizId={currentStep.quizId} challengeId={challengeId} solutionId={solution?._id} />
                  )}
                </div>
              </>
            )
          )}

          {/* Bottom navigation */}
          <div className="sticky bottom-0 border-t-[0.5px] border-solid border-colors-border-neutral bg-colors-background-screen p-4">
            <div className="flex items-center gap-6">
              <button
                className={`appearance-none bg-transparent border-none p-0 m-0 text-current ${currentStepIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                onClick={handlePreviousModule}
                disabled={currentStepIndex === 0}
              >

                <Icon name="arrow_back.svg" category="challenge" className="relative flex-[0_0_auto]"
                  alt="Go back" />
              </button>

              <div className="flex flex-col items-center justify-center gap-0.5 relative flex-1 grow">
                <div className="relative  mt-[-1.00px] font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-colors-content-primary text-[length:var(--body-small-strong-font-size)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] whitespace-nowrap [font-style:var(--body-small-strong-font-style)]">
                  Step {currentStepIndex + 1} of {challenge.solutionSteps.length}
                </div>

                {/* <div className="relative  font-label-regular font-[number:var(--label-regular-font-weight)] text-colors-content-secondary text-[length:var(--label-regular-font-size)] tracking-[var(--label-regular-letter-spacing)] leading-[var(--label-regular-line-height)] whitespace-nowrap [font-style:var(--label-regular-font-style)]">
                  {Math.round(((currentStepIndex + 1) / challenge.solutionSteps.length) * 100)}% complete
                </div> */}
              </div>

              <button
                className={`appearance-none bg-transparent border-none p-0 m-0 text-current ${currentStepIndex === challenge.solutionSteps.length - 1 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                onClick={handleNextModule}
                disabled={currentStepIndex === challenge.solutionSteps.length - 1}
              >
                <Icon name="arrow_forward.svg" category="challenge" className="relative flex-[0_0_auto]"
                  alt="Go forward" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChallengeStages;


