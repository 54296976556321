import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "components/utils/icon";
import { moduleService } from "services";
import { Subject } from "@shared/types/subject";
import Skeleton from "react-loading-skeleton";
import { ModuleCard } from "./moduleCard";

// Reusable Subject Card Function
export const SubjectCard: React.FC<{ subject: Subject }> = ({ subject }) => {
    const [modules, setModules] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
  
    const navigate = useNavigate();
  
    // useEffect(() => {
    //   const fetchModules = async () => {
    //     try {
    //       const response = await moduleService.find();
  
    //       console.log("aarti modules", response);
  
    //       // Filter the fetched modules to include only those in subject.modules
    //       const filteredModules = response.data.filter((module: any) =>
    //         subject.modules.includes(module._id)
    //       );
  
    //       setModules(filteredModules);
    //     } catch (error) {
    //       console.error("Error fetching modules:", error);
    //     } finally {
    //       setLoading(false);
    //     }
    //   };
    //   fetchModules();
    // }, [subject.modules]);
  
    useEffect(() => {
      const fetchModules = async () => {
        try {
          // Pass subject.modules as moduleIds in the query parameters
          const response = await moduleService.find({ moduleIds: subject.modules });
    
          console.log("Filtered modules", response);
    
          // Directly set the filtered modules
          setModules(response.data);
        } catch (error) {
          console.error("Error fetching modules:", error);
        } finally {
          setLoading(false);
        }
      };
      
      if (subject.modules.length > 0) {
        fetchModules();
      }
    }, [subject.modules]);
    
    const handleViewSubject = () => {
      navigate(`/home/subjects/${subject._id}`, { state: { subject, modules } });
    };
  
    return (
      <div className="flex flex-col p-4 mb-6 [font-family:'Inter',Helvetica]">
        {/* Subject Heading */}
        <div className="flex flex-row">
          <Icon
            name={subject.icon || "default-icon.svg"}
            category="subjects"
            alt={subject.name}
            className="relative h-12 w-12 mt-1"
          />
          <h1 className="font-title-small mb-6 ml-1 mt-[3px] whitespace-nowrap text-xl font-[number:var(--title-small-font-weight)] leading-[var(--title-small-line-height)] tracking-[var(--title-small-letter-spacing)] text-[#111111] [font-family:'Inter',Helvetica] [font-style:var(--title-small-font-style)]">
            {subject.name}
          </h1>
        </div>
  
        {/* Subject Description */}
        <p className=" mb-4 text-justify text-[length:var(--body-small-font-size)] font-[number:var(--body-small-font-weight)] leading-[var(--body-small-line-height)] tracking-[var(--body-small-letter-spacing)] text-colors-content-secondary [font-family:'Inter',Helvetica] [font-style:var(--body-small-font-style)]">
          {subject.description || "No description available."}
        </p>
  
        <div className="flex flex-row justify-between">
          {/* Modules Count */}
          <div className="flex h-[41px] items-center whitespace-nowrap rounded-3xl bg-colors-interactive-secondary px-4 py-2 text-sm font-normal leading-[30px]  tracking-[0] text-black [font-family:'GT_America-Regular',Helvetica]">
            {modules.length || 8} modules
          </div>
  
          {/* View All Button */}
          <div onClick={handleViewSubject} className="mt-4 flex flex-row cursor-pointer">
            <div
              className="whitespace-nowrap font-body-strong-link text-[length:var(--body-strong-link-font-size)] font-[number:var(--body-strong-link-font-weight)] leading-[var(--body-strong-link-line-height)] tracking-[var(--body-strong-link-letter-spacing)] text-black underline [font-style:var(--body-strong-link-font-style)]"
            >
              View all
            </div>
          </div>
        </div>
  
        {/* Modules */}
        <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {loading ? (
            // Render 4 skeletons in a grid layout
          Array(4).fill(null).map((_, index) => (
            <Skeleton key={index} width={273} height={160} borderRadius={20} />
          ))
          ) : (
            // Render only the first 4 ModuleCard components
            modules.slice(0, 4).map((module) => (
              <ModuleCard key={module._id} module={module} />
            ))
          )}
        </div>
      </div>
    );
  };