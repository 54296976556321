import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Challenge, DifficultyLevel } from "@shared/types/challenge";
import { Subject } from "@shared/types/subject";
import { solutionService } from "services";
import { Solution, Status } from "@shared/types/solution";
import { Image } from "components/utils/image";

interface ChallengeCardProps {
  challenge: Challenge;
  subject: Subject;
  bgClass: string;
  textColor: string;
  disable: boolean;
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({
  challenge,
  subject,
  bgClass,
  textColor,
  disable
}) => {
  const [currentSolution, setCurrentSolution] = useState<any>(null); // Single solution or null
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSolution = async () => {
      try {
        // Fetch solutions from the server (use sort: {createdAt: -1} on the server)
        const response = await solutionService.find({
          challengeId: challenge.id,
        });
        // If no solutions, set currentSolution to null
        if (response.data.length === 0) {
          setCurrentSolution(null);
        } else {
          // Set the most recent solution (first after sorting) as the current solution
          setCurrentSolution(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setCurrentSolution(null); // Handle errors gracefully
      }
    };

    fetchSolution();
  }, [challenge.id]);

  const customButtonProps = () => {
    switch (currentSolution?.status) {
      case Status.Completed:
        return {
          label: "Retake Challenge",
          style: "bg-orange-500 hover:bg-orange-600",
        }; // Orange for "Retake Challenge"
      case Status.InProgress:
        return {
          label: "Resume Challenge",
          style: "bg-blue-500 hover:bg-blue-600",
        }; // Blue for "Resume Challenge"
      default:
        return {
          label: "Start Challenge",
          style: "bg-green-500 hover:bg-green-600",
        }; // Fallback
    }
  };

  const { label, style } = customButtonProps();

  const handleChallengeClick = (challenge: Challenge) => {
    navigate(`/home/challenge/${challenge._id}`, {
      state: { challenge },
    });
  };

  const getDifficultyDetails = (level: number) => {
    const label = DifficultyLevel[level] ?? "Unknown";
    const color = ["green", "orange", "red"][level] ?? "gray";
    return { label, color };
  };
  
  const diffLevelProps = getDifficultyDetails(challenge.difficultyLevel);
  console.log("current solution", currentSolution);
  return (
    // ChallengeCard component
    <>
      <div className={`relative flex flex-row cursor-pointer w-auto `} onClick={() => handleChallengeClick(challenge)}>
        {disable && (
          <div className="absolute inset-0 flex items-center justify-start pl-16 pt-24 z-[999]">
            <Image
              name="lock.svg"
              category=""
              alt="lock icon"
              className=""
            />
          </div>
        )}
      </div>
      <div className={`relative flex flex-row cursor-pointer w-auto ${disable ? "opacity-25 cursor-not-allowed pointer-events-none":""} `} onClick={() => handleChallengeClick(challenge)}>
      {/* Image and Challenge Info in a row */}
      {/* Challenge Image */}
      {/* Lock Overlay */}
      
      <div className="relative h-[196px] w-[200px] overflow-hidden rounded-tl-lg bg-white">
        <Image
          name={challenge.iconFile}
          category="challenges"
          alt={challenge.name}
          className="absolute left-0 top-0 h-full w-full object-cover"
        />
        {/* <img
          className="absolute left-0 top-0 h-full w-full object-cover"
          alt="Challenge Image"
          src={ `/assets/images/challenges/challenge1/${challenge.iconFile}`|| "https://c.animaapp.com/ja9imYGr/img/cleanshot-2024-09-02-at-22-33-02-2x-1@2x.png"}
        /> */}
      </div>
      {/* Challenge Information */}
      <div className="flex w-full flex-col items-start p-4">
        <h3 className="self-stretch mb-2 [font-family:'Inter',Helvetica] font-semibold text-[#111111] text-base tracking-[0] leading-6">
          {challenge.name}
        </h3>

        {/* Description with line clamp */}
        <p className="relative self-stretch font-body-small font-[number:var(--body-small-font-weight)] text-[#050505] text-[length:var(--body-small-font-size)] tracking-[var(--body-small-letter-spacing)] leading-[var(--body-small-line-height)] [font-style:var(--body-small-font-style)]">
          {challenge.description}
          <br />
          {/* <span>
            Syllabus - {challenge.curriculumStd} Standard | Duration -{" "}
            {challenge.duration}
          </span> */}
        </p>

        <div className="flex items-center justify-between absolute bottom-7">
          <button
            className={`px-4 py-2 border-none bg-colors-interactive-primary rounded-[10px] font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-colors-background-elevated text-[length:var(--body-small-strong-font-size)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] whitespace-nowrap [font-style:var(--body-small-strong-font-style)]`}
          // style={{
          //   width: "100%",
          //   maxWidth: "calc(100% - 16px)",
          // }}
          >
            {label}
          </button>
        </div>
      </div>
    </div>
    </>

  );
};

export default ChallengeCard;
