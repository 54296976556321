import LogoutButton from 'components/utils/logout';
import React, { useState } from 'react';

interface UserAvatarProps {
    type?: 'small' | 'medium'; // Renamed type values for better clarity
    collapsed?: boolean;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ type = 'small', collapsed = false }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const user = JSON.parse(sessionStorage.getItem('authInfo')).user;

    const handleAvatarClick = () => {
        setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown visibility
    };

    const handleCloseDropdown = () => {
        setIsDropdownOpen(false); // Close the dropdown
    };

    const getInitials = (name: string, maxLen?: number) => {
        const normalizedName = name.trim().replace(/\s+/g, ' ');
        const matches = normalizedName.match(/\b\w/g);
        if (!matches) return '';
        const initials = matches.join('').toUpperCase();
        return maxLen ? initials.slice(0, maxLen) : initials;
    };

    const userInitials = (user: { name?: string; id: string }, max = 2) => {
        return (user.name ? getInitials(user.name, max) : user.id.slice(0, max)).toUpperCase();
    };

    function smallAvatar() {
        return (
            <>
                <div
                    className="inline-flex items-center gap-2.5 p-2 relative flex-[0_0_auto] bg-[#a21caf14] rounded-full cursor-pointer"
                    onClick={handleAvatarClick}
                >
                    {user.profilePicture ? (
                        <img
                            src={user.profilePicture}
                            alt="Profile"
                            className="w-6 h-6 rounded-full object-cover"
                        />
                    ) : (
                        <div className="relative w-6 h-6 mt-[-1.00px] [font-family:'Inter_Display-Medium',Helvetica] font-medium text-fuchsia-700 text-base text-center tracking-[0] leading-6">
                            {userInitials(user)}
                        </div>
                    )}
                </div>
                <div className="relative w-fit [font-family:'Inter_Display-Medium',Helvetica] font-medium text-[#1111118f] text-base text-center tracking-[0] leading-6 whitespace-nowrap">
                    {user.name || user.id}
                </div>
            </>
        );
    }

    function mediumAvatar() {
        return (
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={handleAvatarClick}
          >
            <div
              className="flex items-center justify-center w-10 h-10 bg-[#a21caf14] rounded-full"
            >
                        {user.profilePicture ? (
                            <img
                                src={user.profilePicture}
                                alt="Profile"
                                className="w-full h-full rounded-full object-cover"
                            />
                        ) : (
                            <span className="relative w-6 h-6 mt-[-1.00px] [font-family:'Inter_Display-Medium',Helvetica] font-medium text-fuchsia-700 text-base text-center tracking-[0] leading-6">
                                {userInitials(user)}
                            </span>
                        )}
                    </div>
            {!collapsed && (
              <div className="flex flex-col">
                <div className="font-medium text-colors-content-primary text-sm">
                  {user.name || user.id}
                </div>
                <div className="text-colors-content-secondary text-xs">
                  {user.email}
                </div>
                <LogoutButton collapsed={false}/>
              </div>
            )}
          </div>
        );
    }

    const avatar = type === 'medium' ? mediumAvatar : smallAvatar;

    return (
        <>
            {avatar()}
            {isDropdownOpen && (
                <div
                    className=" absolute mt-3 right-0 w-56 bg-white border-2 border-pink-500 rounded-lg shadow-lg z-10"
                    style={{ top: '100%', right: 0 }}
                >   
                    <ul className="p-6 space-y-4">
                            {user.username && <li><strong>Username:</strong> {user.username}</li>}
                            {user.name && <li><strong>Name:</strong> {user.name}</li>}
                            {user.email && <li><strong>Email:</strong> {user.email}</li>}
                            {user.className && <li><strong>Class:</strong> {user.className}</li>}
                            {user.stream && <li><strong>Stream:</strong> {user.stream}</li>}
                            {user.curriculumGrade && <li><strong>Curriculum Grade:</strong> {user.curriculumGrade}</li>}
                            {user.subscription?.level && <li><strong>Subscription Level:</strong> {user.subscription.level}</li>}
                            {<LogoutButton collapsed={false}/>}
                        </ul>
                </div>
            )}
        </>
    );
};

export default UserAvatar;
