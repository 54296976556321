import React, { useEffect, useState, useRef } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { subjectService, challengeService } from 'services';
import { Subject } from "@shared/types/subject";
import { Challenge } from "@shared/types/challenge";
import ChallengeCard from './challengeCard';
import Icon from 'components/utils/icon';
import Sidebar from 'views/utils/sidebar';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import UserAvatar from 'views/utils/userAvatar';

const Home: React.FC = () => {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [challengesBySubject, setChallengesBySubject] = useState<{ [key: string]: Challenge[] }>({});
  const subjectRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let subjectResponse = await subjectService.find();
        let fetchedSubjects: Subject[] = subjectResponse.data;

        const order = (subject: Subject) => ({
          ai: 0,
          entrepreneurship: 1,
          cybersecurity: 2
        }[subject._id] ?? 3); // Sort 'Others' at the end

        fetchedSubjects = fetchedSubjects.sort((s1, s2) => order(s1) - order(s2));
        setSubjects(fetchedSubjects);

        // Create a ref for each subject
        const refs = fetchedSubjects.reduce((acc, subject) => {
          acc[subject._id] = React.createRef<HTMLDivElement>();
          return acc;
        }, {} as { [key: string]: React.RefObject<HTMLDivElement> });

        subjectRefs.current = refs;

        // Fetch challenges for each subject
        const challengeResponse = await challengeService.find();
        const allChallenges: Challenge[] = challengeResponse.data;

        const challengesGroupedBySubject = fetchedSubjects.reduce<{ [key: string]: Challenge[] }>((acc, subject) => {
          acc[subject._id] = allChallenges.filter(c => c.subjectId === subject._id);
          return acc;
        }, {});

        setChallengesBySubject(challengesGroupedBySubject);

      } catch (error) {
        console.error("Error fetching subjects or challenges:", error); // Log the error for debugging
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Function to scroll left or right for specific subject sections
  const scroll = (ref: React.RefObject<HTMLDivElement>, direction: 'left' | 'right') => {
    if (ref.current) {
      const scrollAmount = 300; // Adjust to control how much to scroll per click
      const newScrollPos =
        direction === 'left' ? ref.current.scrollLeft - scrollAmount : ref.current.scrollLeft + scrollAmount;
      ref.current.scrollTo({ left: newScrollPos, behavior: 'smooth' });
    }
  };

  return (
    <div className="relative w-[1280px] h-[1684px] bg-[#fefdfc] rounded-lg overflow-hidden">

      {/* Header & Branding */}
      <div className="absolute w-[1280px] h-20 top-0 left-0"></div>

      {/* Main Content Section */}
      <div className="absolute w-[955px] h-[1844px] top-20 left-[325px] bg-[#fefbff] border-l [border-left-style:solid] border-[#11111114]">

        <div className="absolute w-[954px] h-[114px] top-0 left-px">
          <div className="inline-flex items-center gap-4 absolute top-[37px] left-[796px]">
            <UserAvatar />
          </div>
          <div className="absolute h-7 top-[42px] left-14 [font-family:'Open_Runde-SemiBold',Helvetica] font-semibold text-[#111111] text-xl tracking-[0] leading-7 whitespace-nowrap">
            Challenges
          </div>
        </div>

        {/* Dynamic Subject Rows */}
        {subjects.map((subject) => (
          <div key={subject._id} className="relative top-[150px]">
            <div className="h-7 pl-20 [font-family:'Open_Runde-SemiBold',Helvetica] font-semibold text-[#111111] text-xl tracking-[0] leading-7 whitespace-nowrap">
              {subject.name}
            </div>

            {/* Arrows for sliding */}
            {challengesBySubject[subject._id]?.length > 3 && (
              <>
                <IoIosArrowBack
                  className="absolute left-0 top-[180px] z-10 cursor-pointer"
                  size={30} // Size of the arrow
                  onClick={() => scroll(subjectRefs.current[subject._id], 'left')}
                />
                <IoIosArrowForward
                  className="absolute right-0 top-[180px] z-10 cursor-pointer"
                  size={30} // Size of the arrow
                  onClick={() => scroll(subjectRefs.current[subject._id], 'right')}
                />
              </>
            )}

            {/* Render Challenges for Each Subject */}
            <div className="flex overflow-hidden gap-6 pl-14 mt-10 mb-8" ref={subjectRefs.current[subject._id]}>
              {loading ? (
                // Show skeletons while loading
                <>
                  <Skeleton width={273} height={160} borderRadius={20} />
                  <Skeleton width={273} height={160} borderRadius={20} />
                  <Skeleton width={273} height={160} borderRadius={20} />
                </>
              ) : (
                challengesBySubject[subject._id]?.map((challenge) => (
                  <div
                    className="flex-shrink-0"
                    style={{ width: '273px' }}
                    key={challenge.id}
                  >
                    <ChallengeCard
                      challenge={challenge} // Pass the whole challenge object
                      subject={subject}     // Pass the subject object
                      bgClass="bg-[#0f766e14]"
                      textColor="text-teal-700"
                      disable={false}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Sidebar */}

      <Sidebar activePage="challenges" />

    </div>
  );
};

export default Home;
