import Icon from "components/utils/icon";
import { Image } from "components/utils/image";

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scroll animation
    });
  };
  return (
    <div className="relative left-0 bottom-0 w-full p-2 bg-gray-50 shadow">
      <div className="w-full mx-auto p-4  flex flex-row justify-between ">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a
            href="https://www.getsetlearn.online/"
            className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <Image
                name="gsl.png"
                category=""
                alt="GSL Logo"
                className="h-10 w-auto"
              />           
 
          </a>
          <div className=" relative flex flex-col pl-12">
            <p className="relative flex font-body-small gap-1 font-[number:var(--body-small-font-weight)] text-black text-[length:var(--body-small-font-size)] tracking-[var(--body-small-letter-spacing)] leading-[var(--body-small-line-height)] [font-style:var(--body-small-font-style)]">
            <a
                href="https://www.getsetlearn.info/about-us/" 
                rel="noopener noreferrer"
                target="_blank"
                className="text-black"
              >
              About
            </a> |
            <a
                href="https://www.getsetlearn.info/privacy-policy/"
                rel="noopener noreferrer"
                target="_blank"
                className="text-black"
              >
              Privacy Policy
            </a> |
            <a
                href="#"
                rel="noopener noreferrer"
                target="_blank"
                className="text-black"
              >
              Terms &amp; Conditions 
            </a>| 
            <a
                href="https://www.getsetlearn.info/contact-us/"
                rel="noopener noreferrer"
                target="_blank"
                className="text-black"
              >
              Contact
            </a>
            </p>
            <p className="relative flex gap-1 font-body-small font-[number:var(--body-small-font-weight)] text-black text-[length:var(--body-small-font-size)] tracking-[var(--body-small-letter-spacing)] leading-[var(--body-small-line-height)] whitespace-nowrap [font-style:var(--body-small-font-style)]">
              <span className="font-[number:var(--body-small-font-weight)] font-body-small [font-style:var(--body-small-font-style)] tracking-[var(--body-small-letter-spacing)] leading-[var(--body-small-line-height)] text-[length:var(--body-small-font-size)]">
                © 2024{"  "}
              </span>

              <a
                href="https://www.getsetlearn.info/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="font-[number:var(--body-small-font-weight)] underline font-body-small [font-style:var(--body-small-font-style)] tracking-[var(--body-small-letter-spacing)] leading-[var(--body-small-line-height)] text-[length:var(--body-small-font-size)]">
                  Get Set Learn
                </span>
              </a>

              <span className="font-[number:var(--body-small-font-weight)] font-body-small [font-style:var(--body-small-font-style)] tracking-[var(--body-small-letter-spacing)] leading-[var(--body-small-line-height)] text-[length:var(--body-small-font-size)]">
                {" "}
                |{" "}
              </span>

              <a
                href="https://www.getsetlearn.info/site-map"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="font-[number:var(--body-small-font-weight)] underline font-body-small [font-style:var(--body-small-font-style)] tracking-[var(--body-small-letter-spacing)] leading-[var(--body-small-line-height)] text-[length:var(--body-small-font-size)]">
                  Sitemap
                </span>
              </a>
            </p>
          </div>
        </div>
        <div className="flex flex-row items-end  pr-10 cursor-pointer" onClick={handleScrollToTop}>
          <Icon name='topArrow.svg' category='dashboard' alt="top arrow" />
          <div className="font-body-strong-link font-[number:var(--body-strong-link-font-weight)] text-black text-[length:var(--body-strong-link-font-size)] tracking-[var(--body-strong-link-letter-spacing)] leading-[var(--body-strong-link-line-height)] underline whitespace-nowrap [font-style:var(--body-strong-link-font-style)]">
          Go to Top
        </div>
        </div>
      </div>
  </div>
  );
};

export default Footer;