import { Button } from "@chakra-ui/react";
import React, { useContext } from "react";
import { BsChevronUp } from "react-icons/bs";
import Console from "components/workarea/console";
import Icon from "components/utils/icon";

interface EditorFooterProps {
  handleRun: () => void;
  handleSubmit: () => void;
  isLoading: boolean;
  loading: boolean;
  saveFile: boolean;
  executed: boolean;
  submitted: boolean;
  evaluationOutput?: Record<string, any>;
  output?: Array<{ type: string; content: string; key: string | number }>;
};

const EditorFooter: React.FC<EditorFooterProps> = ({ handleRun, handleSubmit, isLoading, loading, saveFile, executed, submitted,evaluationOutput,output }) => {
  console.log("executed", saveFile, executed, loading, (loading || saveFile || !executed));
  return (
    <div id="console">
      <div className="flex h-[52px] items-center gap-2 pl-5 pr-4 py-0 relative bg-colors-background-screen border-t-[0.5px] [border-top-style:solid] border-b-[0.5px] [border-bottom-style:solid] border-colors-border-neutral">
        <div className="flex items-center gap-2 relative flex-1 grow">
          <div className="inline-flex items-center gap-2 relative flex-[0_0_auto]">
            <Icon name="slideshow.svg" category="canvas" className="relative w-5 h-5"
              alt="slideshow" />
            <div className="relative w-fit mt-[-1.00px] font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-colors-content-secondary text-[length:var(--body-small-strong-font-size)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] whitespace-nowrap [font-style:var(--body-small-strong-font-style)]">
              Output
            </div>
          </div>
        </div>

        <div className="inline-flex items-center justify-end gap-2 relative flex-[0_0_auto]">
          <button onClick={submitted ? undefined : handleRun} disabled={submitted}  className="appearance-none bg-transparent border-none p-0 m-0 text-current cursor-pointer">
            <div className="inline-flex items-center justify-center gap-2.5 px-2.5 py-1.5 relative flex-[0_0_auto] bg-black  rounded-lg border border-solid border-colors-border-neutral">
              <div className="relative w-fit mt-[-1.00px] font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-white  text-[length:var(--body-small-strong-font-size)] text-center tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] whitespace-nowrap [font-style:var(--body-small-strong-font-style)]">
                {isLoading ? 'Loading...' : 'Run Code'}
              </div>
            </div>
          </button>
         
          {/* <div className="inline-flex items-center justify-center gap-1.5 px-2.5 py-1.5 relative flex-[0_0_auto] bg-colors-content-primary rounded-lg">
          <Icon name="slideshow2.png" category="canvas" className="relative w-5 h-5"
              alt="slideshow" /> */}

          {/* <button className="appearance-none bg-transparent border-none p-0 m-0 text-current cursor-pointer">
            <div className="relative w-fit mt-[-0.50px] font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-colors-background-elevated text-[length:var(--body-small-strong-font-size)] text-center tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] whitespace-nowrap [font-style:var(--body-small-strong-font-style)]">
              Preview output
            </div>
            </button> */}
          {/* </div> */}
        </div>        
      </div>
      <Console evaluationOutput={evaluationOutput} output={output} />
    </div>

  );
};
export default EditorFooter;
