import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "components/utils/icon";
import { subjectService } from "services";
import { Subject } from "@shared/types/subject";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Menubar from "components/menubar";
import { SubjectCard } from "components/card/subjectCard";
import Footer from "components/footer/Footer";
import UserAvatar from "views/utils/userAvatar";

const Subjects: React.FC = () => {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await subjectService.find();
        setSubjects(response.data);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchSubjects();
  }, []);

  return (
    <>
    <div className="flex bg-[#fefdfc] pb-6">
      <Menubar activePage="subjects" />

      {/* Main Content */}
      <div className="flex w-full flex-col   ">
        {/* Adjust ml-60 based on sidebar width */}
        <div className="mb-2 pl-8 flex items-center justify-between rounded-xl border border-colors-border-neutral bg-colors-background-elevated p-4">
          <div>
            <h2 className="whitespace-nowrap font-title-large text-[length:var(--title-large-font-size)] font-[number:var(--title-large-font-weight)] leading-[var(--title-large-line-height)] tracking-[var(--title-large-letter-spacing)] text-[#111111] [font-style:var(--title-large-font-style)]">
              Subjects
            </h2>
            <div className="inline-flex items-center absolute top-4 gap-1 left-[1456px]">
              <UserAvatar />
            </div>
            {/* Breadcrumb */}
            <div className="flex flex-row font-body-small-strong text-[length:var(--body-small-strong-font-size)] font-[number:var(--body-small-strong-font-weight)] leading-[var(--body-small-strong-line-height)] tracking-[var(--body-small-strong-letter-spacing)] underline [font-style:var(--body-small-strong-font-style)]">
              Subjects
              <Icon
                name={"right.svg"}
                category="subjects"
                alt={"right"}
                className="relative h-12 w-12"
              />
            </div>
          </div>
          {/* <div className="flex w-[310px] items-center gap-2.5 rounded-xl border-[1.5px] border-solid border-colors-border-neutral bg-colors-background-elevated px-3.5 py-3">
            <span className="whitespace-nowrap font-body-regular text-[length:var(--body-regular-font-size)] font-[number:var(--body-regular-font-weight)] leading-[var(--body-regular-line-height)] tracking-[var(--body-regular-letter-spacing)] text-colors-content-primary [font-style:var(--body-regular-font-style)]">
              Search Module/ Subject
            </span>
          </div> */}
        </div>
        {/* Subject Cards */}
        <div className="flex flex-col p-4">
        {loading ? (
            <>
              <div>
                <Skeleton 
                  width={1173} 
                  height={190} 
                  borderRadius={20} 
                  count={3} 
                  style={{ marginBottom: '16px' }}
                />
              </div>   
            </>
          ) : (
            subjects.map((subject) => (
              <SubjectCard key={subject._id} subject={subject} />
            ))
          )}
        </div>
      </div>
    </div>
    <div>
      <Footer />
    </div>
    </>
  );
};

export default Subjects;
