import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import 'react-loading-skeleton/dist/skeleton.css';
import {  useLocation } from "react-router-dom";
import Menubar from 'components/menubar';
import Icon from 'components/utils/icon';
import { ModuleCard } from 'components/card/moduleCard';
import Footer from 'components/footer/Footer';

const SubjectDetail = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    const subject = location.state?.subject;
    const modules = location.state?.modules


  return (
    <>
        <div className="flex bg-[#fefdfc] pb-12 ">
        <Menubar activePage="subjects" />

        {/* Main Content */}
        <div className="flex flex-col w-full"> {/* Adjust ml-60 based on sidebar width */}
            <div className="flex justify-between items-center p-4 bg-colors-background-elevated rounded-xl border border-colors-border-neutral mb-2">
            <div>
                <h2 className="text-[length:var(--title-large-font-size)] tracking-[var(--title-large-letter-spacing)] leading-[var(--title-large-line-height)] font-title-large font-[number:var(--title-large-font-weight)] text-[#111111] whitespace-nowrap [font-style:var(--title-large-font-style)]">
                    {subject?.name}
                </h2>
                {/* Breadcrumb */}
                <div className="underline flex flex-row font-body-small-strong [font-style:var(--body-small-strong-font-style)] font-[number:var(--body-small-strong-font-weight)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] text-[length:var(--body-small-strong-font-size)]">
                    Subjects
                    <Icon
                        name={'right.svg'}
                        category="subjects"
                        alt={'right'}
                        className="relative w-12 h-12"
                    />
                    {subject?.name}
                </div>
            </div>
            </div>

            <div className="flex flex-col p-4">

            {/* Subject Description */}
            <p className="font-body-small font-[number:var(--body-small-font-weight)] text-colors-content-secondary text-[length:var(--body-small-font-size)] text-justify tracking-[var(--body-small-letter-spacing)] leading-[var(--body-small-line-height)] [font-style:var(--body-small-font-style)] mb-4">
                {subject.description || 'No description available.'}
            </p>

            {/* Subject Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-8">
                {(
                // Render ModuleCard components when modules are not empty
                modules.map((module) => <ModuleCard key={module._id} module={module} />)
                )}
            </div>
            </div>
        </div>
    </div>
    <div>
      <Footer />
    </div>
    </>
  )
}

export default SubjectDetail