import Menubar from 'components/menubar';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { challengeService, solutionService } from 'services';
import { Challenge } from "@shared/types/challenge";
import { Solution, Status } from '@shared/types/solution';
import { Image } from 'components/utils/image';
import { DifficultyLevel } from '@shared/types/challenge';
import Skeleton from 'react-loading-skeleton';
import Footer from 'components/footer/Footer';

const ChallengeOverview: React.FC = () => {
  const { challengeId } = useParams<{ challengeId: string }>();
  const [challenge, setChallenge] = useState<Challenge | null>(null);
  const [currentSolution, setCurrentSolution] = useState<Solution | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChallengeAndSolution = async () => {
      try {
        const challengeResponse = await challengeService.get(challengeId);
        setChallenge(challengeResponse.data);

        const solutionResponse = await solutionService.find({ challengeId });
        if (solutionResponse.data.length > 0) {
          setCurrentSolution(solutionResponse.data[0]);
        } else {
          setCurrentSolution(null);
        }
      } catch (error) {
        console.error('Error fetching challenge or solution:', error);
      }
    };

    if (challengeId) {
      fetchChallengeAndSolution();
    }
  }, [challengeId]);

  const customButtonProps = () => {
    const baseStyle = 'bg-colors-interactive-primary hover:bg-opacity-80';
    switch (currentSolution?.status) {
      case Status.Completed:
        return { label: 'Retake Challenge', style: baseStyle };
      case Status.InProgress:
        return { label: 'Resume Challenge', style: baseStyle };
      default:
        return { label: 'Start Challenge', style: baseStyle };
    }
  };

  const { label, style } = customButtonProps();

  const handleChallengeClick = () => {
    if (currentSolution && currentSolution.status === Status.InProgress) {
      navigate(`/default/code-editor/${challengeId}/${currentSolution._id}`, { state: { challenge, solution: currentSolution } });
    } else {
      navigate(`/default/code-editor/${challengeId}`, { state: { challenge } });
    }
  };

  if (!challenge) {
    return (
      <div className="relative w-full h-[1129px] bg-[#fefdfc] overflow-hidden">

        {/* Skeleton for Title */}
        <div className="absolute top-[127px] left-[324px] bg-[#fdfdfd]">
          <Skeleton width={996} height={45} />
        </div>

        {/* Skeleton for Challenge Image */}
        <div className="absolute w-[972px] h-[404px] top-[195px] left-[331px]">
          <div className="absolute w-[308px] h-[336px] top-[34px] left-6 bg-white rounded-lg overflow-hidden">
            <Skeleton height={336} width={308} />
          </div>
        </div>

        {/* Skeleton for Text Content */}
        <div className="flex flex-col w-[605px] items-start gap-2 absolute top-[230px] left-[683px]">
          <Skeleton width={200} height={24} />
          <Skeleton count={4} width={600} />
        </div>

        {/* Skeleton for Progress Bar */}
        <div className="absolute w-[409px] h-6 top-[470px] left-[683px]">
          <Skeleton width={530} height={24} />
        </div>

        {/* Skeleton for Button */}
        <div className="absolute top-[517px] left-[683px]">
          <Skeleton width={163} height={48} />
        </div>

        {/* Skeleton for Details */}
        <div className="absolute top-[627px] left-[324px] bg-[#fdfdfd]">
          <Skeleton width={996} height={65} />
        </div>


        <div className="absolute w-[976px] h-[316px] top-[727px] left-[324px] bg-[#fdfdfd] border border-solid border-[#d3d3d3]">
          <div className="inline-flex h-[236px] items-start relative top-10 left-10">
            {/* Skeleton for Learning Objectives */}
            <div className="inline-flex flex-col h-[236px] items-start gap-1 p-6 relative flex-[0_0_auto] bg-[#f8f8f8] border border-solid border-[#eaeaea]">
              <div className="inline-flex flex-col items-start gap-4 relative flex-[0_0_auto]">
                <Skeleton width={150} height={20} />
                <Skeleton count={5} width={415} />
              </div>
            </div>

            {/* Skeleton for Skills to Gain */}
            <div className="inline-flex flex-col h-[236px] items-start gap-1 p-6 relative flex-[0_0_auto] -ml-px bg-[#f8f8f8] border border-solid border-[#eaeaea]">
              <div className="flex flex-col w-96 items-start gap-4 relative">
                <Skeleton width={150} height={20} />
                <Skeleton count={6} width={320} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const difficultyLabel = DifficultyLevel[challenge.difficultyLevel] ?? "Undefined";

  return (
    <>
      <div className="flex w-full bg-[#fefdfc] pb-12">
      <Menubar activePage="challenges" />
      <div className="relative flex w-screen ">
        <div className="relative w-full bg-[#fefdfc] border-l border-[#d3d3d3] overflow-hidden">
          {/* Title and Breadcrumbs */}
          <div className="relative px-6 py-8">
            <p className="relative w-fit mt-[-1.00px] font-title-large font-[number:var(--title-large-font-weight)] text-[#111111] text-[length:var(--title-large-font-size)] tracking-[var(--title-large-letter-spacing)] leading-[var(--title-large-line-height)] whitespace-nowrap [font-style:var(--title-large-font-style)]">
              {challenge.name}
            </p>
            <p className="mt-2 text-base text-[#111111]">
              <span className="underline font-body-small-strong [font-style:var(--body-small-strong-font-style)] font-[number:var(--body-small-strong-font-weight)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] text-[length:var(--body-small-strong-font-size)]">
                Subjects</span> &gt;{" "}
              <span className="underline font-body-small-strong [font-style:var(--body-small-strong-font-style)] font-[number:var(--body-small-strong-font-weight)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] text-[length:var(--body-small-strong-font-size)]">
                Artificial Intelligence</span> &gt;{" "}
              <span className="underline font-body-small-strong [font-style:var(--body-small-strong-font-style)] font-[number:var(--body-small-strong-font-weight)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] text-[length:var(--body-small-strong-font-size)]">
                Linear Regression</span> &gt;
              <span className="font-body-small-strong [font-style:var(--body-small-strong-font-style)] font-[number:var(--body-small-strong-font-weight)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-line-height)] text-[length:var(--body-small-strong-font-size)]">
                {challenge.name}
              </span>
            </p>
          </div>

          {/* Challenge Description Section */}
          <div className="relative flex flex-col lg:flex-row items-start gap-6 px-6 mt-6">
            {/* Image Section */}
            <div className="relative flex-shrink-0 w-[308px] h-[366px] bg-white rounded-lg overflow-hidden">
              <Image
                name={challenge.iconFile ?? "defaultChallengeIcon.png"}
                category="challenges"
                alt={challenge.name || "Challenge Image"}
                className="absolute left-0 top-0 h-full w-full object-cover"
              />
            </div>

            {/* Text and Progress Section */}
            <div className="flex-1 flex flex-col gap-4 max-w-full">
              <p className="relative self-stretch mt-[-1.00px] font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-colors-content-primary text-[length:var(--body-small-strong-font-size)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] [font-style:var(--body-small-strong-font-style)] font-bold text-xl">
                Challenge Overview
              </p>
              <p className="relative self-stretch font-body-small font-[number:var(--body-small-font-weight)] text-colors-content-secondary text-[length:var(--body-small-font-size)] text-justify tracking-[var(--body-small-letter-spacing)] leading-[var(--body-small-line-height)] [font-style:var(--body-small-font-style)] line-clamp-10 mr-8 max-h-[200px] overflow-hidden hover:overflow-y-auto hover:scrolling-touch pr-2">
                {challenge.overview}
              </p>

              {/* Progress Bar */}
              <div className="absolute w-[700px] h-6 top-[280px] left-[363px] bg-[url(https://c.animaapp.com/LUnjR1d2/img/frame-1533209101.svg)] bg-[100%_100%]">
                <div className="relative w-[214px] h-6 bg-teal-700 rounded-[16px_0px_0px_16px] overflow-hidden">
                  <div className="absolute h-[22px] -top-px left-3 [font-family:'Open_Runde-Regular',Helvetica] font-normal text-white text-base text-center tracking-[0] leading-[22px] whitespace-nowrap">
                    34%
                  </div>
                </div>
              </div>

              {/* Action Button */}
              <button
                className={`flex w-[163px] items-center justify-center gap-2.5 px-4 py-3 absolute top-[323px] left-[363px] bg-colors-interactive-primary rounded-[10px] font-body-small-strong font-[number:var(--body-small-strong-font-weight)] text-colors-background-elevated text-[length:var(--body-small-strong-font-size)] tracking-[var(--body-small-strong-letter-spacing)] leading-[var(--body-small-strong-line-height)] whitespace-nowrap [font-style:var(--body-small-strong-font-style)] border-none shadow-none ${style}`}
                onClick={handleChallengeClick}
              >
                {label}
              </button>
            </div>
          </div>

          {/* Additional Details Section */}
          <div className="relative w-full flex mt-10 px-6 items-start">
            {/* Level */}
            <div className="flex flex-col w-full items-start gap-1 px-[26px] py-4 relative bg-[#f8f8f8] border border-solid border-[#eaeaea]">
              <div className="relative w-fit mt-[-1.00px] font-subtitle-regular font-[number:var(--subtitle-regular-font-weight)] text-[#0505058f] text-[length:var(--subtitle-regular-font-size)] tracking-[var(--subtitle-regular-letter-spacing)] leading-[var(--subtitle-regular-line-height)] whitespace-nowrap [font-style:var(--subtitle-regular-font-style)]">
                Level
              </div>

              <div className="relative w-fit font-body-regular font-[number:var(--body-regular-font-weight)] text-[#050505] text-[length:var(--body-regular-font-size)] tracking-[var(--body-regular-letter-spacing)] leading-[var(--body-regular-line-height)] whitespace-nowrap [font-style:var(--body-regular-font-style)]">
                {difficultyLabel}
              </div>
            </div>

            <div className="flex flex-col w-full items-start gap-1 px-[26px] py-4 relative -ml-px bg-[#f8f8f8] border border-solid border-[#eaeaea]">
              <div className="relative w-fit mt-[-1.00px] font-subtitle-regular font-[number:var(--subtitle-regular-font-weight)] text-[#0505058f] text-[length:var(--subtitle-regular-font-size)] tracking-[var(--subtitle-regular-letter-spacing)] leading-[var(--subtitle-regular-line-height)] whitespace-nowrap [font-style:var(--subtitle-regular-font-style)]">
                Duration
              </div>

              <div className="relative w-fit font-body-regular font-[number:var(--body-regular-font-weight)] text-[#050505] text-[length:var(--body-regular-font-size)] tracking-[var(--body-regular-letter-spacing)] leading-[var(--body-regular-line-height)] whitespace-nowrap [font-style:var(--body-regular-font-style)]">
                {challenge.duration}
              </div>
            </div>

            <div className="flex flex-col w-full items-start gap-1 px-[26px] py-4 relative -ml-px bg-[#f8f8f8] border border-solid border-[#eaeaea]">
              <div className="relative w-fit mt-[-1.00px] mr-[-15.00px] font-subtitle-regular font-[number:var(--subtitle-regular-font-weight)] text-[#0505058f] text-[length:var(--subtitle-regular-font-size)] tracking-[var(--subtitle-regular-letter-spacing)] leading-[var(--subtitle-regular-line-height)] whitespace-nowrap [font-style:var(--subtitle-regular-font-style)]">
                Prerequisites
              </div>

              <div className="relative w-fit font-body-regular font-[number:var(--body-regular-font-weight)] text-[#050505] text-[length:var(--body-regular-font-size)] tracking-[var(--body-regular-letter-spacing)] leading-[var(--body-regular-line-height)] whitespace-nowrap [font-style:var(--body-regular-font-style)]">
                {challenge.prerequisites || "None"}
              </div>
            </div>
          </div>

          {/* Learning Objectives and Skills */}
          <div className="relative bg-[#fdfdfd] border border-solid border-[#d3d3d3] px-8 py-8 mt-10 ml-6 mx-8">
            {/* Learning Objectives and Skills */}
            <div className="flex gap-0">
              {/* Learning Objectives */}
              <div className="inline-flex flex-col max-h-[236px] items-start gap-1 p-6 bg-[#f8f8f8] border border-solid border-[#eaeaea] w-full overflow-hidden hover:overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                <div className="inline-flex flex-col items-start gap-4">
                  <div className="text-[#050505] font-title-small font-[number:var(--title-small-font-weight)] text-[length:var(--title-small-font-size)] tracking-[var(--title-small-letter-spacing)] leading-[var(--title-small-line-height)]">
                    Learning Objectives
                  </div>
                  <p className="w-full font-body-regular font-[number:var(--body-regular-font-weight)] text-[#050505] text-[length:var(--body-regular-font-size)] tracking-[var(--body-regular-letter-spacing)] leading-[var(--body-regular-line-height)]">
                    {challenge.learningObjectives}
                  </p>
                </div>
              </div>

              {/* Skills Section */}
              <div className="inline-flex flex-col max-h-[236px] items-start gap-1 p-6 bg-[#f8f8f8] border border-solid border-[#eaeaea] w-full -ml-px overflow-hidden hover:overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                <div className="flex flex-col w-96 items-start gap-4">
                  <div className="text-[#050505] font-title-small font-[number:var(--title-small-font-weight)] text-[length:var(--title-small-font-size)] tracking-[var(--title-small-letter-spacing)] leading-[var(--title-small-line-height)]">
                    Skills
                  </div>
                  <ul className="list-none space-y-4 pl-0">
                    {challenge.skills.map((skill, index) => (
                      <li key={index} className="flex items-start gap-2">
                        {/* Custom bullet point */}
                        <img
                          className="w-1.5 h-1.5 mt-2"
                          alt="Bullet point"
                          src="https://c.animaapp.com/LUnjR1d2/img/vector-3.svg"
                        />
                        <span className="font-body-strong font-[number:var(--body-strong-font-weight)] text-[#050505] text-[length:var(--body-strong-font-size)] tracking-[var(--body-strong-letter-spacing)] leading-[var(--body-strong-line-height)]">
                          {skill}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer />
    </div>
    </>

  );
};

export default ChallengeOverview;