import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Icon from "components/utils/icon";

interface TopbarProps {
  problemPage?: boolean;
  activePage: string;
  defaultCollapsed?: boolean;
}

interface ItemProps {
  route: string;
  pageName: string;
  iconName: string;
  collapsed: boolean;
}

const Menubar: React.FC<TopbarProps> = ({ problemPage, activePage, defaultCollapsed = false }) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed); // Initialize with defaultCollapsed

  // Debugging log to track state updates
  console.log("Sidebar Collapsed:", isCollapsed);

  function getStyling(page: string, activePage: string): { class: string } {
    const isActive = activePage.toLowerCase() === page.toLowerCase();
    if (isActive) {
      return { class: 'text-[#ab32af]' };
    }
    return { class: 'text-[#888888]' };
  };

   // Reusable Item component 
  const Item: React.FC<ItemProps> = ({ route, pageName, iconName, collapsed }) => {
    const { class: textClass } = getStyling(pageName, activePage);

    return (
      <div
        onClick={() => navigate(route)}
        className={`flex items-center gap-2.5 p-2 relative self-stretch w-full flex-[0_0_auto] cursor-pointer rounded-md ${textClass}`}
      >
        <Icon
          name={`${iconName}.svg`}
          category="menubar"
          className="relative w-5 h-5"
          alt={pageName}
        />
        {!collapsed && (
          <div className="relative w-fit mt-[-1.00px] font-body-small-strong">
            {pageName}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar: Adjust width dynamically */}
      <div
        className={`fixed left-0 top-0 ${isCollapsed ? 'w-20' : 'w-64'} h-full bg-colors-background-screen -z-1 flex flex-col transition-all duration-300`}
      >
        <div className="flex items-center justify-between px-4 py-2 relative w-full border-b-[0.5px] [border-bottom-style:solid] border-colors-border-neutral">
          <div
            onClick={() => navigate("/home/dashboard")}
            className="inline-flex cursor-pointer items-center gap-1.5 p-1 relative rounded-md"
          >
            {/* <img src="/assets/icons/menubar/gsl.png" className="relative w-16 h-8 mr-2" alt="GSL logo" /> */}
            <Icon name="solveit.svg" category="menubar" className="relative" alt="Frame" />
            {!isCollapsed && (
              <div className="font-semibold text-colors-interactive-primary text-base leading-6 whitespace-nowrap">
                Solve_it
              </div>
            )}
          </div>
          <Icon
            name="left_panel_close.svg"
            category="menubar"
            className="relative cursor-pointer"
            alt="Close Panel"
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
        </div>

        {/* Sidebar Content */}
        <div className="flex flex-col items-start gap-0.5 p-3 w-full flex-grow">
          <Item route="/home/dashboard" pageName="Dashboard" iconName="dashboard" collapsed={isCollapsed} />
          <Item route="/home/subjects" pageName="Subjects" iconName="subjects" collapsed={isCollapsed} />
        </div>
      </div>

      {/* Main Content Area */}
      <div className={`flex-1 ${isCollapsed ? 'ml-20' : 'ml-64'} overflow-auto transition-all duration-300`}>
        {/* Your page content goes here */}
      </div>
    </div>
  );
};

export default Menubar;
