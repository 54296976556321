import Icon from "components/utils/icon";
import Sidebar from "views/utils/sidebar";
import React, { useEffect, useState } from "react";
import { challengeService, subjectService } from "services";
import { useNavigate } from 'react-router-dom';
import { Challenge } from "@shared/types/challenge";
import ChallengeCard from "views/home/challengeCard";
import UserAvatar from "views/utils/userAvatar";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Menubar from "components/menubar";
import Footer from "components/footer/Footer";

const Dashboard: React.FC = () => {

  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await subjectService.find();
        setSubjects(response.data);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };

    const fetchChallenges = async () => {
      try {
        const response = await challengeService.find();
        setChallenges(response.data);
      } catch (error) {
        console.error("Error fetching challenges:", error);
      }
    };

    const fetchData = async () => {
      try {
        await Promise.all([fetchSubjects(), fetchChallenges()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false only after both requests are done
      }
    };

    fetchData();
  }, []);


  const exploreSubjects = subjects.slice(0, 3);

  if (loading) {
    return (
      <div className="relative w-full h-[1129px] bg-[#fefdfc] overflow-hidden">
        
        {/* Skeleton for Title */}
        <div className="absolute top-[97px] left-[324px] bg-[#fdfdfd]">
          <Skeleton width={996} height={45} />
        </div>

        <div className="absolute top-[225px] left-[324px] bg-[#fdfdfd]">
          <Skeleton width={596} height={255} />
        </div>

      <div className="absolute top-[535px] left-[324px] bg-[#fdfdfd]">
        <Skeleton width={1096} height={255} />
      </div>

      <div className="absolute top-[805px] left-[324px] bg-[#fdfdfd]">
        <Skeleton width={1096} height={255} />
      </div>

      <div className="absolute top-[1075px] left-[324px] bg-[#fdfdfd]">
        <Skeleton width={1096} height={255} />
      </div>

      </div>
    );
  }

  const handleChallengeClick = (challengeId: string, challengeName: string) => {
    navigate(`/default/code-editor/${challengeId}`, { state: { challengeName } });
  };

  const subjectMap = subjects.reduce((acc: { [key: string]: any }, subject) => {
    acc[subject._id] = subject;
    return acc;
  }, {});


  const cardStyles = 'flex flex-col w-[273px] h-[160px] items-start gap-4 p-6 rounded-3xl';

  return (
    <>
    <div className="flex bg-[#fefbff] w-full pb-12">
      <Menubar activePage="dashboard" />
      <div className="relative flex  rounded-lg overflow-x-hidden pb-6 ">
        <div className="relative w-screen h-full top-0 bg-[#fefbff] border-l [border-left-style:solid] border-[#11111114]">
          <div className="relative h-10 top-24 left-14 [font-family:'Open_Runde-Bold',Helvetica] font-bold text-[#111111] text-[32px] tracking-[-0.30px] leading-10 whitespace-nowrap">
            Welcome back, {JSON.parse(sessionStorage.getItem('authInfo')).user.id}
          </div>
          <div className="relative w-full h-[114px] top-0 left-px">
            <div className="inline-flex items-center gap-4 absolute top-0 right-12">
              <UserAvatar />
            </div>
            <div className="relative h-7 top-0 left-14 [font-family:'Open_Runde-SemiBold',Helvetica] font-semibold text-[#111111] text-xl tracking-[0] leading-7 whitespace-nowrap">
              Dashboard
            </div>
          </div>
          <p className="relative h-[22px] top-0 left-14 [font-family:'Open_Runde-Regular',Helvetica] font-normal text-black text-base tracking-[0] leading-[22px] whitespace-nowrap">
            Pick up where you left off
          </p>
          <div className="relative w-[743px] h-[280px] top-6 left-14 bg-white rounded-3xl overflow-hidden border border-solid border-[#11111114]">
            <div className="relative w-[436px] h-[102px] top-12 left-6">
              <div className="inline-flex items-center justify-center gap-2.5 px-3 py-1 relative top-0 left-0 bg-[#0f766e14] rounded-full">
                <div className="relative w-fit [font-family:'Inter_Display-Medium',Helvetica] font-medium text-teal-700 text-xs tracking-[1.50px] leading-[18px] whitespace-nowrap">
                  ARTIFICIAL INTELLIGENCE
                </div>
              </div>
              <div className="relative h-7 top-8 left-0 [font-family:'Open_Runde-SemiBold',Helvetica] font-semibold text-[#111111] text-xl tracking-[0] leading-7 whitespace-nowrap">
                Create a Custom Chatbot
              </div>
              <p className="relative h-[22px] top-10 left-0 [font-family:'Open_Runde-Regular',Helvetica] font-normal text-[#111111] text-base tracking-[0] leading-[22px] whitespace-nowrap">
                Current module: Generate product features with ChatGPT
              </p>
            </div>
            {/* 
          Add bar code
          */}
            <div className="inline-flex items-center gap-4 relative top-20 left-6">
              <div className="inline-flex items-center justify-center gap-2.5 px-12 py-4 relative flex-[0_0_auto] bg-fuchsia-700 rounded-2xl">
                <div className="relative w-fit mt-[-2.00px] [font-family:'Open_Runde-Semibold',Helvetica] font-normal text-white text-base text-center tracking-[0.20px] leading-6 whitespace-nowrap">
                  Resume
                </div>
              </div>
              <div className="inline-flex items-center justify-center gap-2.5 px-12 py-4 relative flex-[0_0_auto] bg-[#a21caf14] rounded-2xl">
                <div className="relative w-fit mt-[-2.00px] [font-family:'Open_Runde-Semibold',Helvetica] font-normal text-fuchsia-700 text-base text-center tracking-[0.20px] leading-6 whitespace-nowrap">
                  Subject overview
                </div>
              </div>
            </div>
            <div className="relative w-[102px] h-[102px] -top-16 left-[557px]">
              <Icon name="o0kr5tacm0qt-chat-2-1.png" category="dashboard" alt="Chat" className="relative w-[73px] h-[73px] top-[15px] left-[15px] object-cover" />
            </div>
          </div>
          <div className="relative h-[22px] top-12 left-14 [font-family:'Open_Runde-Regular',Helvetica] font-normal text-black text-base tracking-[0] leading-[22px] whitespace-nowrap">
            Reading materials
          </div>
          <div className="relative flex left-14 top-20">
            <div className="flex space-x-4">
              <div className="flex flex-col w-[273px] h-[290px] items-start gap-4 px-6 py-[26px] relative bg-white rounded-3xl overflow-hidden border border-solid border-[#11111114]">
                <div className="relative self-stretch w-full h-[146px]">
                  <Icon name="1-9-1.png" category="dashboard" alt="Element" className="relative w-[108px] h-[138px] top-1 left-[63px] object-cover" />
                </div>
                <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="relative self-stretch mt-[-1.00px] [font-family:'Inter_Display-SemiBold',Helvetica] font-semibold text-[#111111] text-base tracking-[0] leading-6">
                    Getting Started with Chatbots
                  </div>
                  <div className="relative self-stretch [font-family:'Inter_Display-Regular',Helvetica] font-normal text-[#111111] text-sm tracking-[0] leading-5">
                    Akhil Mittal
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-[273px] h-[290px] items-start gap-4 px-6 py-[26px] relative bg-white rounded-3xl overflow-hidden border border-solid border-[#11111114]">
                <div className="relative self-stretch w-full h-[146px]">
                  <Icon name="2-9.png" category="dashboard" alt="Element" className="relative w-[200px] h-[146px] top-0 left-[13px] object-cover" />
                </div>
                <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                  <p className="relative self-stretch mt-[-1.00px] [font-family:'Inter_Display-SemiBold',Helvetica] font-semibold text-[#111111] text-base tracking-[0] leading-6">
                    Getting Started with ChatGPT and AI Chatbots
                  </p>
                  <div className="relative self-stretch [font-family:'Inter_Display-Regular',Helvetica] font-normal text-[#111111] text-sm tracking-[0] leading-5">
                    Mark Pesce
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-[273px] h-[290px] items-start gap-4 px-6 py-[26px] relative bg-white rounded-3xl overflow-hidden border border-solid border-[#11111114]">
                <div className="relative self-stretch w-full h-[146px]">
                  <Icon name="1-9-1.png" category="dashboard" alt="Element" className="relative w-[108px] h-[138px] top-1 left-[63px] object-cover" />
                </div>
                <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                  <div className="relative self-stretch mt-[-1.00px] [font-family:'Inter_Display-SemiBold',Helvetica] font-semibold text-[#111111] text-base tracking-[0] leading-6">
                    Getting Started with Chatbots
                  </div>
                  <div className="relative self-stretch [font-family:'Inter_Display-Regular',Helvetica] font-normal text-[#111111] text-sm tracking-[0] leading-5">
                    Akhil Mittal
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div> 
      </div>
    </div>
      <div>
        <Footer />
      </div>
    </> 
  );
};

export default Dashboard;